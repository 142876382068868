button {
  border: none;
}

button:hover {
  cursor: pointer;
}

.container {
  position: relative;  
  min-height: calc(100vh - 150px);
  width: 100vw;
  overflow: hidden;
}

.background {

  height: 100%;
  width: 100%;

}

.figure {
  position: absolute;
}

.colors-container {
  padding: 1em 0 1em 1em;
  display: flex;
}

.color-box {
  margin-right: 1em;
  width: 40px;
  height: 20px;
}
